import React from 'react';
import CountdownTimer from './CountdownTimer';
import logo from './journeestalents.png'
import './App.css';


function App() {
  return (
    <div className="App">

      <header className="App-header">
      <div className="cover-photo-container">
      
      <img src={logo} className="App-logo" alt="logo" />
      </div>
        <CountdownTimer />
      </header>
    </div>
  );
}

export default App;
