import React, { useState, useEffect } from 'react';
import './CountdownTimer.css';
import downloadImage from './download1.png'; 

const CountdownTimer = () => {
  const calculateTimeLeft = () => {
    const targetDate = new Date('2025-02-21T11:00:00');
    const now = new Date();
    const difference = targetDate.getTime() - now.getTime();

    let timeLeft = {};
    
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const renderDigits = (time) => {
    const digits = String(time).padStart(2, '0').split('');
    return (
      <div className="time-digits">
        {digits.map((digit, index) => (
          <div key={index} className="digit-container">
            <span className="time">{digit}</span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="countdown-container">
      <h2 className="countdown-title">Loading... session 2025</h2>
      <img src={downloadImage} alt="Download" className="download-image" />
      <div className="countdown-timer">
        <div className="time-unit">
          {renderDigits(timeLeft.days)}
          <h3 className='Days'>Days</h3>
        </div>
        <div className="separator">:</div>
        <div className="time-unit">
          {renderDigits(timeLeft.hours)}
          <h3 className='Hours'>Hours</h3>
        </div>
        <div className="separator">:</div>
        <div className="time-unit">
          {renderDigits(timeLeft.minutes)}
          <h3 className='Minutes'>Minutes</h3>
        </div>
        <div className="separator">:</div>
        <div className="time-unit">
          {renderDigits(timeLeft.seconds)}
          <h3 className='Seconds'>Seconds</h3>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
